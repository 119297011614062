
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
const namespace: string = 'profile';
@Component({
  components: { DefaultLayout }
})
export default class AdminDashboard extends Vue {
  /* Properties */
  /* Store Actions */
  @Action('logout', { namespace }) logout: any;
  /* Watchers */
  /* Data */
  adminActions: any = [
    {
      title: 'Manage Users',
      subtitle: 'Modify individual user accounts',
      pathName: 'ManageUsers'
    },
    {
      title: 'Manage Customers',
      subtitle: 'Act on behalf of a Customer or modify their options',
      pathName: 'ManageCustomer'
    },
    // { title: 'Create a new Customer', subtitle: '', pathName: '' },
    {
      title: 'Refresh Caches',
      subtitle: 'Flush current caches and regrab them from the database',
      pathName: 'ManageCache'
    },
    {
      title: 'Order by File',
      subtitle: 'Renamed from ANS Order Processor',
      pathName: 'OrderByFile'
    },
    {
      title: 'CRM Display',
      subtitle: 'See our customers based on their sales values',
      pathName: 'Crm'
    },
    {
      title: 'Code Manager',
      subtitle: 'Check the status of Amazon request codes',
      pathName: 'CodeManager'
    },
    {
      title: 'Static Data Import',
      subtitle: 'Import items in bulk for transparency customers',
      pathName: 'DataImportation'
    }
  ];
  adminMiscOptions: any = [
    // {
    //   title: 'Sales Information',
    //   subtitle: 'See how our money is doing',
    //   pathName: ''
    // },
    {
      title: 'Activity Log',
      subtitle: 'Who done what',
      pathName: 'Activity Log'
    }
  ];
  /* Methods */
  async goTo(pathName: string, title: string) {
    this.$router.push({ name: pathName });
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
